import React, { CSSProperties, FC } from 'react';
import localStyles from './index.module.css';
import logo from '../../assets/icons/logo-p5.png';

interface IProps {
  styles?: CSSProperties;
  type?: 'single' | 'default';
}

const Loading: FC<IProps> = ({ styles, type }) => {
  return (
    <div className={localStyles.container} style={{ ...styles }}>
      <img className={localStyles.img} src={logo} alt="logo-p5" />
    </div>
  );
};

export default Loading;
