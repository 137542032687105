import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Fragment, useEffect, useState, VFC } from "react";

import Input from "../../../components/input";
import Button from "../../../components/button";
import Wrapper from "../../../components/wrapper";
import validations from "../../../utils/validations";

import { createCourse } from "../../../store/courses/courses.actions";

type FormData = {
  name: string;
  year: string;
};

const AddStudent: VFC = () => {
  const history = useHistory();
  const dispatch: any = useDispatch();

  const { errors, handleSubmit, register } = useForm<FormData>();
  const [loading, setLoading] = useState<boolean>(false);

  const [err, setErr] = useState({
    status: false,
    message: "",
  });

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const onCreate = ({ year, name }: FormData) => {
    setLoading(true);

    dispatch(createCourse({ name, year: parseInt(year) }))
      .then((res: any) => {
        setLoading(false);

        const id = res.payload._id;
        history.push(`/courses/${id}`);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setErr({
          ...err,
          status: true,
          message: "Ha ocurrido un error inesperado, intentá nuevamente!",
        });
      });
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onCreate)} style={{ width: "100%" }}>
        <Wrapper styles={{ marginTop: "24px" }}>
          <Input
            name="name"
            error={errors.name}
            styles={{ width: "30%" }}
            placeholder="Nombre del curso"
            referencia={register(validations.required())}
          />

          <Input
            name="year"
            type="number"
            placeholder="Año"
            error={errors.year}
            styles={{ width: "30%" }}
            referencia={register(validations.required())}
          />

          {err.status && <div>{err.message}</div>}

          <Button submit type="secondary" text="Crear" disabled={loading} />
        </Wrapper>
      </form>
    </Fragment>
  );
};

export default AddStudent;
