const validations = {
  email: {
    required: 'Ingresá un email válido',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Ingresá un email válido',
    },
  },

  required: (message?: string) => ({
    required: message || 'Completá este campo.',
  }),

  password: {
    required: 'Especificá una contraseña',
    minLength: {
      value: 6,
      message: 'La contraseña debe tener al menos 8 caracteres',
    },
  },
};

export default validations;
