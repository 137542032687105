import { combineReducers } from 'redux';

// reducers
import auth from './auth/auth.reducer.js';
import courses from './courses/courses.reducer';
import students from './students/students.reducer';
import repositories from './repositories/repositories.reducer';

export default combineReducers({
  auth,
  courses,
  students,
  repositories,
});
