import { FC, MouseEvent, Dispatch, SetStateAction } from "react";
import localStyles from "./Modal.module.css";
import closeIcon from "../../assets/icons/x-button.png";

interface iModal {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  delay?: number;
}

const Modal: FC<iModal> = ({ show, setShow, children, delay }) => {
  const toggleModal = (e: MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setShow(!show);
  };

  if (show && delay) {
    setTimeout(() => {
      setShow(!show);
    }, delay);
  }

  return (
    <div
      className={localStyles.container}
      style={{ display: show ? "flex" : "none" }}
      onClick={toggleModal}
    >
      <div className={localStyles.modal}>
        {children}
        <div className={localStyles.footer}>
          <img
            src={closeIcon}
            className={localStyles.closeIcon}
            alt="closeIcon"
            onClick={toggleModal}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
