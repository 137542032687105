// Tipado de la data de parejas:

interface IPair {
  [table: string]: ITable;
}

interface ITable {
  [pair: string]: [string[]]; // -> Un arreglo que tiene un solo elemento en la posicion 0 que es un arreglo de strings
}

const oneTable = (
  pairs: string[][],
  length: number,
  table: { [k: string]: any } = {}
) => {
  const copyPairs = pairs;
  for (let i = 1; i <= length; i++) {
    let index = `pp ${i}`;
    table[index] = copyPairs.splice(0, 1);
  }
  return table;
};

const tables = (pairs: string[][], tables: { [k: string]: any } = {}) => {
  let pairsCopy = pairs.slice(0);
  for (let i = 1; i <= pairs.length; i++) {
    // if (pairsCopy.length < 6) {
    //   let index = `table ${i}`;
    //   tables[index] = oneTable(pairsCopy.splice(0), 5);
    //   break;
    // }
    tables[`table ${i}`] = oneTable(pairsCopy.splice(0, 3), 3);
    if (pairsCopy.length < 1) break;
  }
  return tables;
};

const genPairs = (students: string[], rounds: number = 26): IPair[] => {
  let oddStundent: string = "";
  const stundentsCount =
    students.length % 2 === 0 ? students.length : students.length - 1;
  const half = stundentsCount / 2;

  const pairs: IPair[] = [];

  const studentsIndexes: any[] = students.map((_, i) => i).slice(1);

  for (let round = 0; round < rounds; round++) {
    const roundPairings = [];

    const newStudentsIndexes = [0].concat(studentsIndexes);

    const firstHalf = newStudentsIndexes.slice(0, half);
    const secondHalf = newStudentsIndexes.slice(half).reverse();

    if (students.length % 2 !== 0)
      oddStundent = students[secondHalf[secondHalf.length - 1]];

    for (let i = 0; i < firstHalf.length; i++) {
      roundPairings.push([students[firstHalf[i]], students[secondHalf[i]]]);
    }

    if (students.length % 2 !== 0)
      roundPairings[roundPairings.length - 1].push(oddStundent);

    // rotating the array
    studentsIndexes.push(studentsIndexes.shift());
    pairs.push(tables(roundPairings));
  }

  return pairs;
};

export default genPairs;
export type {IPair, ITable}