import { FETCH_REPOSITORIES, FILTER_REPOSITORIES } from "./repositories.actions";

const initialState = {
  allRepos: [],
  Workshops: [],
  Checkpoints: [],
  Reviews: [],
  Demos: [],
  Repasos: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPOSITORIES:
      return {
        ...state,
        allRepos: action.payload,
      };
    case FILTER_REPOSITORIES:
        return {
          ...state,
          Workshops: action.payload.WSRepos,
          Checkpoints: action.payload.checkpointsRepos,
          Reviews: action.payload.reviewsRepos,
          Demos: action.payload.demosRepos,
          Repasos: action.payload.repasos
        }


    default:
      return state;
  }
};

export default reducer;
