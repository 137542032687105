import React, { ReactNode, CSSProperties, FC } from 'react';
import localStyles from './index.module.css';

interface ICardContainer {
  children: ReactNode;
  styles?: CSSProperties;
}

const Card: FC<ICardContainer> = ({ children, styles }) => {
  return (
    <div className={localStyles.container} style={{ ...styles }}>
      {children}
    </div>
  );
};

export default Card;
