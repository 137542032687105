import React, { FC, CSSProperties, MouseEvent } from 'react';
import localStyles from './index.module.css';

type Props = {
  text: string;
  styles?: CSSProperties;
  onClick?: (event: MouseEvent<HTMLHeadElement>) => void;
};

const Button: FC<Props> = ({ styles, text, onClick }) => {
  return (
    <div className={`${localStyles.container}`}>
      <h2
        className={`${localStyles.text}`}
        style={{ ...styles, cursor: onclick ? 'pointer' : 'default' }}
        onClick={onClick}
      >
        {text}
      </h2>
    </div>
  );
};

export default Button;
