import { useHistory } from "react-router-dom";
import { Fragment, FC, MouseEvent } from "react";

import Title from "../../../../components/title";
import Button from "../../../../components/button";
import Wrapper from "../../../../components/wrapper";
import StudentBanner from "../../../../components/student-banner";

interface IProps {
  errors: {
    status: boolean;
    message: string;
    students: any[];
  };
}

const ViewResults: FC<IProps> = ({ errors }) => {
  const history = useHistory();

  const goHome = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    history.replace("/");
  };

  const onClick = (e: any) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <Wrapper>
        <Title text={errors.message} />

        {errors.students.map((student: any) => (
          <StudentBanner
            mode="single"
            id={student._id}
            key={student._id}
            name={student.name}
            email={student.email}
            github={student.github}
            callback={onClick}
          />
        ))}

        <Button text="Ir a home" type="primary" onClick={goHome} />
      </Wrapper>
    </Fragment>
  );
};

export default ViewResults;
