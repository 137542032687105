import { FieldError } from 'react-hook-form';
import { FC, CSSProperties, Fragment } from 'react';

import s from './index.module.css';

type Props = {
  name: string;
  value?: string;
  referencia?: any;
  error?: FieldError;
  placeholder?: string;
  styles?: CSSProperties;
  type?: 'text' | 'number' | 'password';
  defaultValue?: string | number
};

const Input: FC<Props> = ({
  styles,
  name,
  type,
  placeholder,
  referencia,
  error,
  defaultValue
}) => {
  return (
    <Fragment>
      <input
        name={name}
        ref={referencia}
        className={s.input}
        style={{ ...styles }}
        type={type || 'text'}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />

      {error && <div className={s.error}>{error.message}</div>}
    </Fragment>
  );
};

export default Input;
