import { IRepository } from './types';

export const filterRepositories = (data: any): IRepository => {
  return data.map((repo: any) => ({
    _id: repo.name,
    name: repo.name,
    html_url: repo.html_url,
    description: repo.description,
  }));
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
