import Spinner from '../spinner';
import localStyles from './index.module.css';
import { FC, CSSProperties, MouseEvent } from 'react';

type Props = {
  text: string;
  submit?: boolean;
  styles?: CSSProperties;
  disabled?: boolean | false;
  type?: 'primary' | 'secondary';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

const Button: FC<Props> = ({
  type,
  text,
  styles,
  submit,
  onClick,
  disabled,
}) => {
  return (
    <button
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled}
      style={{ ...styles }}
      className={`${localStyles.btn} ${localStyles[type || 'primary']}`}
    >
      {disabled ? (
        <Spinner color={type !== 'secondary' ? '#ff062e' : '#62d0ff'} />
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
