import { CSSProperties, FC, ReactNode } from 'react';
import localStyles from './index.module.css';

type Props = {
  children: ReactNode;
  styles?: CSSProperties;
  //crear una nueva prop
  isInviteView?: boolean;
};

const Wrapper: FC<Props> = ({ children, styles, isInviteView = false }) => {
  return (
    <div className={`${localStyles.container} ${isInviteView && localStyles.inviteViewResponsive}`} style={{ ...styles }} >
      {children}
    </div>
  );
};

export default Wrapper;
