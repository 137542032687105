import { FC, CSSProperties, MouseEvent } from 'react';
import s from './index.module.css';

interface IOptions {
  text: string;
  active: boolean;
  onClick?: (event: MouseEvent<HTMLHeadElement>) => void;
}

type Props = {
  options: IOptions[];
  styles?: CSSProperties;
};

const Subtitles: FC<Props> = ({ styles, options }) => {
  return (
    <div className={`${s.container}`}>
      {options.map(({ text, active, onClick }) => (
        <div className={s.item} onClick={onClick} key={text}>
          <h3 className={`${s.text}`} style={{ ...styles }}>
            {text}
          </h3>
          <div
            className={s.line}
            style={{ background: active ? '#ff062e' : '#0e2547' }}
          />
        </div>
      ))}
    </div>
  );
};

export default Subtitles;
