import React, { FC, Fragment } from 'react';
import localStyles from './index.module.css';

import edit_img from '../../assets/icons/arrow.png';
import delete_img from '../../assets/icons/delete.png';

import { ICardContainer } from './types';

const Card: FC<ICardContainer> = ({
  id,
  name,
  mode,
  email,
  styles,
  github,
  callback,
}) => {
  return (
    <Fragment>
      <div style={styles} className={localStyles.container}>
        <div className={localStyles.text}>
          <div>{name}</div>
          <div>{github}</div>
          <div>{email}</div>
        </div>

        <div className={localStyles.btnContainer}>
          {mode === 'single' && <div>reinvitar (building...)</div>}

          {mode !== 'single' && (
            <>
              <button
                className={localStyles.button}
                onClick={(e) => callback({ e, id, type: 'edit', studentName: name})}
              >
                <img
                  src={edit_img}
                  className={localStyles.img_icon}
                  alt="edit-icon"
                />
              </button>
              <button
                className={localStyles.button}
                onClick={(e) => callback({ e, id, type: 'delete' })}
              >
                <img
                  src={delete_img}
                  className={localStyles.img_icon}
                  alt="delete-icon"
                />
              </button>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Card;
