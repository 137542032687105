import { FETCH_COURSE, FETCH_COURSES } from "./courses.actions";
import { DELETE_STUDENT } from "../students/students.actions";

const initialState = {
  all: [],
  single: {
    students: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURSES:
      return {
        ...state,
        all: action.payload,
      };

    case FETCH_COURSE:
      return {
        ...state,
        single: action.payload,
      };
    case DELETE_STUDENT:
      return {
        ...state,
        single: {
          ...state.single,
          students: state.single.students.filter(
            (student) => student._id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
};

export default reducer;
