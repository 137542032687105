import { IRepository } from "./types";

export const filterRepos = (data: IRepository[]): any => {
     return {
      demosRepos:filterByType(data, "demo"), 
      WSRepos:filterByType(data, "WS"), 
      checkpointsRepos:filterByType(data, "checkpoint"), 
      reviewsRepos:filterByType(data, "review"), 
      repasos:filterByType(data, "repaso")}
  };


  const filterByType = (repos:IRepository[], type: "demo" | "WS" | "checkpoint" | "review" | "repaso") : IRepository[] =>{
      return repos.filter((repo:any) =>{ 
         return repo.name.includes(type)
      })
  }