import { useHistory } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, Fragment, useEffect, useState, MouseEvent } from "react";
import localStyles from "./index.module.css";
import {
  fetchOneStudent,
  deleteStudent,
} from "../../store/students/students.actions";
import { deleteOne } from "../../store/courses/courses.actions";
import { ICallback } from "../../components/student-banner/types";

import Title from "../../components/title";
import Loading from "../../components/loader";
import Wrapper from "../../components/wrapper";
import StudentBanner from "../../components/student-banner";

import { RootState } from "../../utils/types.reducer";
import { fetchCourse } from "../../store/courses/courses.actions";
import Button from "../../components/button";

interface IProps extends RouteComponentProps<MatchParams> {}
interface MatchParams {
  id: string;
}

const SingleCourse: FC<IProps> = ({ match }) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch: any = useDispatch();
  const { single } = useSelector((state: RootState) => state.courses);

  const courseId = match.params.id;

  useEffect(() => {
    dispatch(fetchCourse(courseId)).then(() => setLoading(false));
  }, [dispatch, courseId]);

  const onClick = ({ e, type, id }: ICallback) => {
    e.preventDefault();
    if (type === "edit") {
      dispatch(fetchOneStudent(id)).then(() => {
        history.push(`/courses/${courseId}/updateStudent/${id}`);
      });
    }
    if (type === "delete") {
      setLoading(true);
      dispatch(deleteStudent(id)).then(() => {
        setLoading(false);
      });
    }
  };

  const goToStudent = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    history.push(`/courses/${courseId}/addStudent`);
  };

  const goToPairs = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    history.push(`/courses/${courseId}/pairs`);
  };

  const goToSeed = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    history.push(`/courses/${courseId}/seed`);
  };

  const deleteCourse = (id: string) => {
    dispatch(deleteOne(id)).then(() => history.push("/courses"));
  };

  if (loading) return <Loading />;

  return (
    <Fragment>
      <Title text={single.name} />

      <Wrapper>
        <div className={localStyles.buttons}>
          <Button
            text="Agregar estudiante"
            type="secondary"
            onClick={goToStudent}
          />
          <Button text="Seedear planilla" type="secondary" onClick={goToSeed} />
          <Button text="Ver parejas" type="secondary" onClick={goToPairs} />
        </div>
        <Button
          text="Eliminar curso"
          type="primary"
          onClick={() => deleteCourse(courseId)}
        />
        {single.students &&
          single.students.map(({ _id, name, github, email }) => (
            <StudentBanner
              id={_id}
              key={_id}
              name={name}
              email={email}
              github={github}
              callback={onClick}
            />
          ))}
      </Wrapper>
    </Fragment>
  );
};

export default SingleCourse;
