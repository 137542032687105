import { Switch, Route, Redirect } from "react-router-dom";

//* private components
import Courses from "../views/courses";
import AddStudent from "../views/add-student";
import InviteCourse from "../views/invite-course";
import SingleCourse from "../views/single-course";
import Navbar from "../views/navbar";
import UpdateStudent from "../views/update-student";
import Pairs from "../views/pairs";
import AddStudentsBySheets from "../views/add-students-by-sheets";
import DayPairs from "../views/day-pairs";

const AuthRoutes = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <Route
          exact
          path="/courses/:id/updateStudent/:studentId"
          component={UpdateStudent}
        />
        <Route exact path="/courses/:id/addStudent" component={AddStudent} />
        <Route exact path="/courses/:id/pairs" component={Pairs}/>
        <Route exact path="/courses/:id/pairs/:day" component={DayPairs}/>
        <Route exact path="/courses/:id/seed" component={AddStudentsBySheets}/>
        <Route exact path="/courses/:id" component={SingleCourse} />
        <Route exact path="/courses" component={Courses} />

        <Route path="/share-repository/" component={InviteCourse} />

        <Redirect to="/courses" />
      </Switch>
    </>
  );
};

export default AuthRoutes;
