import { FC, useState, Fragment, useRef } from 'react';
import s from './index.module.css';

import { ISelect } from '../../utils/types';
import { useOutsideClick } from '../../hooks/useOutsideClick';

type Props = {
  selectName: string;
  options: any;
  value: ISelect;
  onChange: (value: ISelect, name: string) => void;
};

const Select: FC<Props> = ({ value, options, onChange, selectName }) => {
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = ({ _id, name }: ISelect) => () => {
    setIsOpen(false);
    onChange({ ...value, name, _id }, selectName);
  };

  useOutsideClick(wrapperRef, isOpen, setIsOpen);

  return (
    <Fragment>
      <div className={s.input} onClick={toggling}>
        {value.name}
      </div>
      {isOpen && (
        <div className={s.drpodownlist} ref={wrapperRef}>
          {options.map(({ _id, name }: any) => (
            <li
              className={s.li}
              key={Math.random()}
              onClick={onOptionClicked({ _id, name })}
            >
              {name}
            </li>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default Select;
