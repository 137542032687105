import { useEffect, useState, VFC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
//-----------------------------------------------------------------//
import { RootState } from "../../utils/types.reducer";
import { fetchCourse } from "../../store/courses/courses.actions";
import genPairs, { IPair } from "../../utils/genPairs";
import Card from "../../components/card";
import Title from "../../components/title";
import Wrapper from "../../components/wrapper";
import CardContainer from "../../components/card-container";
import Loading from "../../components/loader";
import workshops from "../../utils/workshops";

interface MatchParams {
  id: string;
}

const Pairs: VFC = () => {
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const dispatch: any = useDispatch();
  const courseId = match.params.id;
  const [bootcampPairs, setBootcampPairs] = useState<IPair[]>([]);
  const [loading, setLoading] = useState(false);
  const [clickedPairs, setClickedPairs] = useState<number[]>(() =>
    JSON.parse(localStorage.getItem("clickedPairs") || "[]")
  ); // Se usa una función cuando quiero que se compute solo en el montado (como un useEffect() con deps -> [])
  const { students } = useSelector((state: RootState) => state.courses.single);

  useEffect(() => {
    if (!students.length) {
      setLoading(true);
      dispatch(fetchCourse(courseId)).then(() => setLoading(false));
    } else {
      const studentsNames = students.map((student) => student.name);
      const allPairs = genPairs(studentsNames, workshops.length);
      setBootcampPairs(allPairs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courseId, students.length]);

  const handleClick = (pair: IPair, index: number): void => {
    if (!clickedPairs.includes(index + 1)) {
      clickedPairs.push(index + 1);
    }
    localStorage.setItem("clickedPairs", JSON.stringify(clickedPairs));
    setClickedPairs(clickedPairs);
    history.push(`/courses/${courseId}/pairs/${index + 1}`, pair);
  };

  if (loading) return <Loading />;

  return (
    <>
      <Title text="Pairs" />
      <Wrapper>
        {bootcampPairs.length && (
          <CardContainer>
            {bootcampPairs.map((dayPairs, index) => {
              return (
                <Card
                  title={workshops[index]}
                  key={index}
                  clicked={!!clickedPairs[index]}
                  onClick={() => handleClick(dayPairs, index)}
                />
              );
            })}
          </CardContainer>
        )}
      </Wrapper>
    </>
  );
};

export default Pairs;
