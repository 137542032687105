import React, { FC, MouseEvent } from 'react';
import localStyles from './index.module.css';

interface ICard {
  title: string;
  year?: number;
  clicked?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const Card: FC<ICard> = ({ title, year, clicked, onClick }) => {
  return (
    <div className={localStyles.container} onClick={onClick}>
      <h3 className={clicked ? localStyles.clicked : localStyles.text}>{title}</h3>
      {year && <p className={localStyles.year}>{year}</p>}
    </div>
  );
};

export default Card;
