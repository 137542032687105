import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

//* auth components
import Login from '../views/login';

const AuthRoutes = () => {
  return (
    <Switch>
      <Route exact path="/auth/login" component={Login} />

      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default AuthRoutes;
