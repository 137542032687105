import axios, { AxiosRequestConfig } from "axios";
interface IFetchAPI {
  method: string;
  url: string;
  body?: object;
}

const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
};

export const instance: any = axios.create(config);

export const fetchAPI = ({ method, url, body }: IFetchAPI) => instance[method](url, body);
