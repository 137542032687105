import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Fragment, FC, useState, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
//-------------------------------------------------------------------//
import Input from "../../components/input";
import Title from "../../components/title";
import Button from "../../components/button";
import Wrapper from "../../components/wrapper";

import validations from "../../utils/validations";
import {
  updateOneStudent,
  fetchOneStudent,
} from "../../store/students/students.actions";

import { RootState } from "../../utils/types.reducer";

interface IProps extends RouteComponentProps<MatchParams> {}

interface MatchParams {
  id: string;
  studentId: string;
}

type FormData = {
  name: string;
  email: string;
  github: string;
  imgUrl?: string;
};

const UpdateStudent: FC<IProps> = ({ match }) => {
  const { id } = match.params; //course id
  const { studentId } = match.params; //student id

  const history = useHistory();
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, errors, register } = useForm<FormData>();

  const [err, setErr] = useState({
    status: false,
    message: "",
  });

  const onUpdate = ({ email, github, name, imgUrl }: FormData) => {
    console.log("img URLLLL", imgUrl);
    setLoading(true);
    dispatch(
      updateOneStudent(studentId, { email, github, name, courseId: id, imgUrl })
    )
      .then(() => {
        setLoading(false);
        history.push(`/courses/${id}`);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setErr({
          ...err,
          status: true,
          message: "Ha ocurrido un error inesperado, intentá nuevamente!",
        });
      });
  };

  const { singleStudent } = useSelector((state: RootState) => state.students);

  useEffect(() => {
    dispatch(fetchOneStudent(studentId));
  }, [dispatch, studentId]);

  return (
    <Fragment>
      <Title text="Editar Alumno  " />
      <form onSubmit={handleSubmit(onUpdate)} style={{ width: "100%" }}>
        <Wrapper styles={{ marginTop: "24px" }}>
          <Input
            name="name"
            error={errors.name}
            styles={{ width: "30%", textAlign: "center" }}
            placeholder="Nombre y Apellido"
            referencia={register(validations.required())}
            defaultValue={singleStudent.name && `${singleStudent.name}`}
          />

          <Input
            name="email"
            error={errors.email}
            styles={{ width: "30%", textAlign: "center" }}
            placeholder="Correo Electrónico"
            referencia={register(validations.email)}
            defaultValue={singleStudent.email && `${singleStudent.email}`}
          />

          <Input
            name="github"
            placeholder="GitHub"
            error={errors.github}
            styles={{ width: "30%", textAlign: "center" }}
            referencia={register(validations.required())}
            defaultValue={singleStudent.github && `${singleStudent.github}`}
          />

          <Input
            name="imgUrl"
            placeholder="Imagen URL"
            error={errors.imgUrl}
            styles={{ width: "30%", textAlign: "center" }}
            referencia={register()}
            defaultValue={singleStudent.imgUrl && `${singleStudent.imgUrl}`}
          />

          {err.status && <div>{err.message}</div>}

          <Button submit text="Actualizar" type="primary" disabled={loading} />
        </Wrapper>
      </form>
    </Fragment>
  );
};

export default UpdateStudent;
