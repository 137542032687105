import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import s from "./index.module.css";
import Input from "../../components/input";
import Title from "../../components/title";
import Button from "../../components/button";
import validations from "../../utils/validations";

import { loginUser } from "../../store/auth/auth.actions";

type FormData = {
  email: string;
  password: string;
};

const Login = () => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, errors, register } = useForm<FormData>();

  const [error, setError] = useState({
    status: false,
    message: "",
  });

  const onSubmit = ({ email, password }: FormData) => {
    setLoading(true);
    dispatch(loginUser({ email, password }))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError({
          ...error,
          status: true,
          message: "Usuario o contraseña incorrecta",
        });
      });
  };

  return (
    <div className={s.backgroud}>
      <form className={s.inputsContainer} onSubmit={handleSubmit(onSubmit)}>
        <Title text="Iniciar Sesión" styles={{ fontSize: "24px" }} />

        <Input
          name="email"
          error={errors.email}
          styles={{ width: "80%" }}
          placeholder="Correo electrónico"
          referencia={register(validations.email)}
        />

        <Input
          name="password"
          type="password"
          error={errors.password}
          placeholder="Contraseña"
          styles={{ width: "80%" }}
          referencia={register(validations.password)}
        />

        <div className={s.btnContainer}>
          <Button
            submit
            type="primary"
            disabled={loading}
            text="Iniciar Sesión"
          />
        </div>

        {error.status && <div className={s.mainError}>{error.message}</div>}
      </form>
    </div>
  );
};

export default Login;
