import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, MouseEvent, useEffect, useState, VFC } from 'react';

import Card from '../../components/card';
import Title from '../../components/title';
import Wrapper from '../../components/wrapper';
import AddCourse from './components/AddCourse';
import Subtitles from '../../components/titles-options';
import CardContainer from '../../components/card-container';

import { RootState } from '../../utils/types.reducer';
import { fetchCourses } from '../../store/courses/courses.actions';

const Courses: VFC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [active, setActive] = useState<string>('courses');
  const { all } = useSelector((state: RootState) => state.courses);

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  const goToCourse = (e: MouseEvent<HTMLDivElement>, id: string) => {
    e.preventDefault();
    history.push(`/courses/${id}`);
  };

  const subtitleOptions = [
    {
      text: 'Cursos',
      active: active === 'courses' || false,
      onClick: (e: MouseEvent<HTMLHeadElement>) => {
        e.preventDefault();
        setActive('courses');
      },
    },
    {
      text: 'agregar curso',
      active: active === 'addcourse' || false,
      onClick: (e: MouseEvent<HTMLHeadElement>) => {
        e.preventDefault();
        setActive('addcourse');
      },
    },
  ];

  return (
    <Fragment>
      <Title text="Bootcamps" />
      <Subtitles options={subtitleOptions} />

      <Wrapper>
        {active === 'courses' && (
          <CardContainer>
            {all &&
              all.map(({ _id, name, year }) => (
                <Card
                  title={name}
                  key={_id}
                  year={year}
                  onClick={(e) => goToCourse(e, _id)}
                />
              ))}
          </CardContainer>
        )}
        {active === 'addcourse' && <AddCourse />}
      </Wrapper>
    </Fragment>
  );
};

export default Courses;
