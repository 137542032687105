import { FETCH_STUDENTS, FETCH_STUDENT, UPDATE_STUDENT} from "./students.actions";

const initialState = {
  all: [],
  singleStudent: {}
};

const updateStudent = (newStudent) => {
  const oldStudentIndex = initialState.all.findIndex((student) => student.id = newStudent.id)
  return initialState.all[oldStudentIndex] = newStudent
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STUDENTS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_STUDENT:  
      return {
        ...state,
        singleStudent: action.payload
      }
    case UPDATE_STUDENT:
      return {
        ...state, all: updateStudent(action.payload)
      }

    default:
      return state;
  }
};

export default reducer;
