import React, { FC } from 'react';
import s from './index.module.css';

type Props = {
  color?: string;
};

const Spinner: FC<Props> = ({ color }) => {
  return (
    <div
      className={s.container}
      style={{ borderTopColor: color || 'transparent' }}
    ></div>
  );
};

export default Spinner;
