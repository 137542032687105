import { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Fragment, FC, useState, useEffect, MouseEvent } from "react";

import { RootState } from "../../utils/types.reducer";
import { ISelectOption, ISelect } from "../../utils/types";

import {
  fetchRepositories,
  filterAll,
  inviteCollaborators,
} from "../../store/repositories/repositories.actions";
import { fetchCourses } from "../../store/courses/courses.actions";

import Title from "../../components/title";
import Button from "../../components/button";
import Wrapper from "../../components/wrapper";
import Select from "../../components/input-select";
import ViewResults from "./components/view-results";
import { filterRepos } from "../../utils/filterRepos";
import Modal from "../../components/modal";

import localStyles from "./index.module.css";

interface IProps extends RouteComponentProps<MatchParams> {}
interface MatchParams {
  id: string;
}

const AddStudent: FC<IProps> = () => {
  const dispatch: any = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const { all } = useSelector((state: RootState) => state.courses);
  const types = [
    { name: "Workshops", _id: "1" },
    { name: "Checkpoints", _id: "2" },
    { name: "Reviews", _id: "3" },
    { name: "Demos", _id: "4" },
    { name: "Repasos", _id: "5" },
  ];
  const repositories = useSelector((state: RootState) => state.repositories);

  const [failedInvite, setFailedInvite] = useState({
    status: false,
    message: "",
    students: [],
  });

  const [error, setError] = useState({
    status: false,
    message: "",
  });

  const [options, setOptions] = useState<ISelectOption>({
    repo: {
      name: "Seleccionar...",
      _id: "",
    },
    type: {
      name: "Workshops",
      _id: "",
    },
    course: {
      name: "Seleccionar...",
      _id: "",
    },
  });

  useEffect(() => {
    dispatch(fetchCourses());
    dispatch(fetchRepositories());
  }, [dispatch]);

  useEffect(() => {
    let categories = filterRepos(repositories.allRepos);
    dispatch(filterAll(categories));
  }, [repositories.allRepos, dispatch]);

  const onChange = (value: ISelect, name: string) => {
    setOptions({ ...options, [name]: value });
  };

  const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);

    if (options.repo.name && options.course._id) {
      dispatch(
        inviteCollaborators({
          name: options.repo.name,
          courseId: options.course._id,
        })
      )
        .then(({ data }: AxiosResponse) => {
          if (data.errors.length) {
            setFailedInvite({
              ...failedInvite,
              status: true,
              students: data.errors,
              message: `Error al invitar a ${data.errors.length} persona${
                data.errors.length < 2 ? "" : "s"
              }`,
            });
          } else {
            setShow(true);
          }
          setLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError({
        ...error,
        status: true,
        message: "Seleccione un bootcamp y un repositorio",
      });
    }
  };

  return (
    <Fragment>
      {!failedInvite.status ? (
        <>
          <Title text="Invitar a repositorios" />
          {show && (
            <Modal show={true} setShow={setShow}>
              <div className={localStyles.header}>
                <h1>Success! </h1>
              </div>
              <div className={localStyles.body}>
                <p>
                  El repositorio{" "}
                  <span className={localStyles.highlighter}>
                    {options.repo.name}
                  </span>{" "}
                  fue enviado exitosamente al curso{" "}
                  <span className={localStyles.highlighter}>
                    {options.course.name}
                  </span>
                  .{" "}
                </p>
              </div>
            </Modal>
          )}
          <Wrapper isInviteView>
            <Wrapper>
              <Title text="Cursos" styles={{ fontSize: "24px" }} />
              <Select
                options={all}
                onChange={onChange}
                selectName="course"
                value={options.course}
              />
            </Wrapper>
            <Wrapper>
              <Title text="Tipo" styles={{ fontSize: "24px" }} />
              <Select
                selectName="type"
                options={types}
                value={options.type}
                onChange={onChange}
              />
            </Wrapper>
            <Wrapper>
              <Title text="Repo" styles={{ fontSize: "24px" }} />
              <Select
                selectName="repo"
                options={repositories[options.type.name]}
                value={options.repo}
                onChange={onChange}
              />
            </Wrapper>
          </Wrapper>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10vh",
            }}
          >
            <Button
              text="Invitar"
              type="secondary"
              onClick={onSubmit}
              disabled={loading}
            />

            {error.status && (
              <div
                style={{
                  color: "#ff062e",
                  textAlign: "center",
                }}
              >
                {error.message}
              </div>
            )}
          </div>
        </>
      ) : (
        <ViewResults errors={failedInvite} />
      )}
    </Fragment>
  );
};

export default AddStudent;
