import React, { VFC, Fragment } from 'react';
import { Provider } from 'react-redux';

import store from '../../store/store';
import Routes from '../../routes';

const App: VFC = () => {
  return (
    <Fragment>
      <Provider store={store}>
        <Routes />
      </Provider>
    </Fragment>
  );
};

export default App;
