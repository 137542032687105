import React, { useEffect, useRef, useState, VFC } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
//-------------------------------------------------------------------//
import styles from "./index.module.css";
import { IPair } from "../../utils/genPairs";
import { RootState } from "../../utils/types.reducer";
import Modal from "../../components/modal";
import modalStyles from "../invite-course/index.module.css";
import Loading from "../../components/loader";
import { fetchCourse } from "../../store/courses/courses.actions";
import {
  getStudentImgByName,
  handleFilterPerson,
  handleEdit,
  addElementToRef,
  getImage,
} from "./day-pairs-utils";

interface MatchParams {
  id: string;
}

const DayPairs: VFC = () => {
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const dispatch: any = useDispatch();
  const pairs = history.location.state as IPair;
  const courseId = match.params.id;
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [tables, setTables] = useState<string[][][]>(
    Object.values(pairs).map((table) => Object.values(table).flat())
  );
  const tablesRef = useRef<HTMLDivElement[]>([]);
  const { students } = useSelector((state: RootState) => state.courses.single);

  useEffect(() => {
    if (!students.length) {
      setLoading(true);
      dispatch(fetchCourse(courseId)).then(() => setLoading(false));
    }
  }, [dispatch, students.length, courseId]);

  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  if (loading) return <Loading />;

  return (
    <div className={styles.container}>
      {show && (
        <Modal show={true} setShow={setShow} delay={1150}>
          <div className={modalStyles.header}>
            <h1>Success!</h1>
          </div>
          <div className={styles.modalTitleContainer}>
            <p>Las parejas fueron copiadas al portapapeles!</p>
            {image && (
              <img
                src={image}
                alt="table preview"
                className={styles.previewImg}
              />
            )}
          </div>
        </Modal>
      )}
      <div className={styles.header}>
        <h1 className={styles.center}>Mesas </h1>
        <button
          className={
            editMode ? styles.editButtonEnabled : styles.editButtonDisabled
          }
          onClick={() => handleEdit(editMode, setEditMode)}
          title="Editar mesas"
        >
          📝
        </button>
      </div>
      {tables.map((table, i) => (
        <div
          className={styles.table}
          key={`table${i}`}
          ref={(element: HTMLDivElement) => addElementToRef(element, tablesRef)}
        >
          <button
            className={editMode ? styles.tableTitleDisabled : styles.tableTitle}
            onClick={async () =>
              tablesRef.current[i] &&
              (await getImage(tablesRef.current[i], setImage, setShow))
            }
            disabled={editMode}
            title="Copiar al portapapeles"
          >
            Mesa {i + 1}
          </button>
          {table.map((pair, i) => (
            <div
              className={table.length > 1 ? styles.pair : styles.justOnePair}
              key={`pair${i}`}
            >
              <h3 className={styles.center}>Pair {i + 1}</h3>
              <hr />
              {pair.flat().map((person, i) => (
                <div className={styles.personContainer} key={`person${i}`}>
                  <div className={styles.person}>
                    <img
                      src={getStudentImgByName(person, students)}
                      className={styles.profileImg}
                      alt="profile"
                    />
                    <p>{person}</p>
                  </div>
                  {editMode && (
                    <button
                      onClick={() =>
                        handleFilterPerson(person, tables, setTables)
                      }
                      className={styles.deleteButton}
                    >
                      X
                    </button>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DayPairs;
