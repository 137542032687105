import html2canvas from "html2canvas";
//-----------------------------------------------//
import { iStudent } from "../../utils/types";

const getStudentImgByName = (
  nameToFind: string,
  students: iStudent[]
): string | undefined => {
  const student = students.find((student) => student.name === nameToFind);
  return student ? student.imgUrl : undefined;
};

const handleFilterPerson = (
  personToFilter: string,
  tables: string[][][],
  setTables: React.Dispatch<React.SetStateAction<string[][][]>>
) => {
  // Chequeo sí la persona está en una pair de 3 o más
  const checkIfAlreadyPairedWithMoreThan2 = (
    tables: string[][][],
    personToFind: string
  ): boolean => {
    for (const table of tables) {
      for (const pair of table) {
        if (pair.includes(personToFind)) {
          return pair.length > 2;
        }
      }
    }
    return false;
  };

  const isPairedWith3OrMore = checkIfAlreadyPairedWithMoreThan2(
    tables,
    personToFilter
  );

  if (isPairedWith3OrMore) {
    const filteredTables = tables.map((table) =>
      table.map((pair) => pair.filter((person) => person !== personToFilter))
    );

    setTables(filteredTables);
  } else {
    // Saco de abajo para arriba la primera pair que tenga dos personas
    const tablesCopy = JSON.parse(JSON.stringify(tables)) as string[][][];
    let lastTwoPersonPair: string[] = [];
    let shouldBreak: boolean = false; // -> Para romper el primer bucle despues de romper el de adentro
    for (let i = tablesCopy.length - 1; i >= 0; i--) {
      for (let j = tablesCopy[i].length - 1; j >= 0; j--) {
        if (
          tablesCopy[i][j].length === 2 &&
          !tablesCopy[i][j].includes(personToFilter)
        ) {
          lastTwoPersonPair = tablesCopy[i].splice(j, 1).flat();
          shouldBreak = true;
          break;
        }
      }
      if (shouldBreak) break;
    }

    // Filtro la persona de la mesa y le añado la pair de dos personas a esa pair
    for (const table of tablesCopy) {
      for (const pair of table) {
        if (pair.includes(personToFilter)) {
          pair.splice(pair.indexOf(personToFilter), 1);
          pair.push(...lastTwoPersonPair);
        }
      }
    }

    // Chequeo y saco las pairs que están vacías
    for (const table of tablesCopy) {
      for (const pair of table) {
        if (!pair.length) {
          table.splice(table.indexOf(pair, 1));
        }
      }
    }

    // Chequeo y saco las mesas que están vacías
    for (const table of tablesCopy) {
      if (!table.length) {
        tablesCopy.splice(tablesCopy.indexOf(table), 1);
      }
    }

    setTables(tablesCopy);
  }
};

const handleEdit = (
  editMode: boolean,
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
) => setEditMode(!editMode);

const addElementToRef = (
  element: HTMLDivElement,
  tablesRef: React.MutableRefObject<HTMLDivElement[]>
) => {
  if (element && !tablesRef.current.includes(element)) {
    tablesRef.current.push(element);
  }
};

const copyImageToClipboard = async (base64Image: string) => {
  try {
    const res = await fetch(base64Image);
    const blob = await res.blob();
    await navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob,
      }),
    ]);
  } catch (error) {
    console.log(error);
  }
};

const takeScreenshot = async (refCurrentElement: HTMLDivElement) => {
  try {
    const canvas = await html2canvas(refCurrentElement, {
      allowTaint: true,
      useCORS: true,
      backgroundColor: "#000929",
    });
    const base64Image = canvas.toDataURL("image/png", 1.0);
    await copyImageToClipboard(base64Image);
    return base64Image;
  } catch (error) {
    console.log(error);
  }
};

const getImage = async (
  refCurrentElement: HTMLDivElement,
  setImage: React.Dispatch<React.SetStateAction<string>>,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const base64Image = await takeScreenshot(refCurrentElement);
  base64Image && setImage(base64Image);
  setShow(true);
};

export {
  getStudentImgByName,
  handleFilterPerson,
  handleEdit,
  addElementToRef,
  getImage,
};
