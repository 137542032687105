import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { VFC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../utils/types.reducer';

// import Modal from './components/Modal/Modal';

import Button from '../../components/button';
import localStyles from './index.module.css';
import logo_extended from '../../assets/images/logo-extended.png';

import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store/auth/auth.actions';

const Navbar: VFC = () => {
  const history = useHistory();
  const dispatch: any = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);

  // const [showModal, setShowModal] = useState<boolean>(false);
  // const toggleModal = (e: MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault();
  //   setShowModal(!showModal);
  // };

  const onLogout = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  const goHome = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    return history.push('/');
  };

  return (
    <div className={localStyles.container}>
      <img
        className={localStyles.logo}
        src={logo_extended}
        alt="logo-extended"
        onClick={goHome}
      />

      <div className={localStyles.linkContainer}>
        <Link className={localStyles.links} to="/courses">
          Bootcamps
        </Link>

        <Link className={localStyles.links} to="/share-repository">
          Invitar
        </Link>

        {user.token && (
          <Button
            text="Salir"
            type="primary"
            onClick={onLogout}
            styles={{ marginLeft: 18 }}
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
