import { fetchAPI } from "../../config/axios.instance";
import { parseJwt } from "../../utils/filterData";

// constants
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const FETCH_USER = "FETCH_USER";

const login = (res) => ({
  type: LOGIN_USER,
  payload: res,
});

const logout = (res) => ({
  type: LOGOUT_USER,
  payload: res,
});

export const loginUser = (body) => (dispatch) =>
  fetchAPI({ method: "post", url: "/auth/login", body }).then(({ data }) => {
    const token = data.response;
    localStorage.setItem("token", token);
    const payload = parseJwt(token);

    return dispatch(login({ ...payload, token }));
  });

export const checkUSer = () => (dispatch) => {
  const token = localStorage.getItem("token");

  if (token) {
    const payload = parseJwt(token);
    return dispatch(login({ ...payload, token }));
  }

  return;
};

export const logoutUser = () => (dispatch) =>
  fetchAPI({
    method: "get",
    url: "auth/logout",
  }).then(() => {
    localStorage.removeItem("token");
    return dispatch(logout({}));
  });
