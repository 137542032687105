import { fetchAPI } from "../../config/axios.instance";

// constants
export const FETCH_COURSE = "FETCH_COURSE";
export const FETCH_COURSES = "FETCH_COURSES";
export const CREATE_COURSE = "CREATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE"

const fetchAll = (res) => ({
  type: FETCH_COURSES,
  payload: res,
});

const fetchSingle = (res) => ({
  type: FETCH_COURSE,
  payload: res,
});

const create = (res) => ({
  type: CREATE_COURSE,
  payload: res,
});

const deleteCourse = (res) => ({
  type: DELETE_COURSE,
  payload: res,
})

export const fetchCourses = () => async (dispatch) =>
  fetchAPI({ method: "get", url: "/courses" })
    .then(({ data }) => dispatch(fetchAll(data)))
    .catch((err) => {
      console.error(err.status)
      if(err.response.data.status === 404) dispatch(fetchAll([]))
    });

export const fetchCourse = (id) => async (dispatch) =>
  fetchAPI({ method: "get", url: `/courses/${id}` })
    .then(({ data }) => dispatch(fetchSingle(data)))
    .catch((err) => console.error(err));

export const createCourse = (body) => async (dispatch) =>
  fetchAPI({ method: "post", url: "/courses", body })
    .then(({ data }) => dispatch(create(data)))
    .catch((err) => console.error(err));

export const deleteOne = (id) => async (dispatch) =>
fetchAPI({ method: "delete", url: `/courses/${id}` })
  .then(({ data }) => dispatch(deleteCourse(data)))
  .catch((err) => console.error(err));

export const cleanCourse = () => async (dispatch) => dispatch(fetchSingle({students: []}));

// export const deleteCourse = (id) => async (dispatch) =>
//   fetchAPI({ method: 'delete', url: `/students/${id}` })
//     .then(() => dispatch(fetchSingle({})))
//     .catch((err) => console.error(err));
