import { fetchAPI } from "../../config/axios.instance";

// constants
export const CREATE_STUDENT = "CREATE_STUDENT";
export const CREATE_STUDENTS = "CREATE_STUDENTS";
export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const FETCH_STUDENT = "FETCH_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const FETCH_USER = "fetch_user";

const create = (res) => ({
  type: CREATE_STUDENT,
  payload: res,
});

const createMany = (res) => ({
  type: CREATE_STUDENTS,
  payload: res,
});

const fetchSingleStudent = (res) => ({
  type: FETCH_STUDENT,
  payload: res,
});

const fetchAllStudents = (res) => ({
  type: FETCH_STUDENTS,
  payload: res,
});

const updateSingleStudent = (res) => ({
  type: UPDATE_STUDENT,
  payload: res,
});

const deleteSingleStudent = (res) => ({
  type: DELETE_STUDENT,
  payload: res,
});

///////////////////////////////////////

// const fetchSingleUser = (res) => ({
//   type: FETCH_USER,
//   payload: res,
// });

export const createStudent = (body) => async (dispatch) =>
  fetchAPI({ method: "post", url: "/students", body })
    .then(({ data }) => dispatch(create(data)))
    .catch((err) => console.error(err));

export const createStudents = (students) => async (dispatch) =>
  fetchAPI({ method: "post", url: "/students/many", body: students })
    .then(({ data }) => dispatch(createMany(data)))
    .catch((err) => console.log({ err }));

export const deleteStudent = (id) => async (dispatch) =>
  fetchAPI({ method: "delete", url: `/students/${id}` })
    .then(() => dispatch(deleteSingleStudent(id)))
    .catch((err) => console.error(err));

export const getAllStudents = (id) => async (dispatch) =>
  fetchAPI({ method: "get", url: `/students/${id}` })
    .then(({ data }) => dispatch(fetchAllStudents(data)))
    .catch((err) => console.error(err));

export const fetchOneStudent = (id) => async (dispatch) =>
  fetchAPI({ method: "get", url: `/students/${id}` })
    .then(({ data }) => dispatch(fetchSingleStudent(data)))
    .catch((err) => console.error(err));

export const updateOneStudent = (id, body) => async (dispatch) =>
  fetchAPI({ method: "put", url: `/students/${id}`, body })
    .then(({ data }) => dispatch(updateSingleStudent(data)))
    .catch((err) => console.error(err));
