import { fetchAPI } from "../../config/axios.instance";
import { filterRepositories } from "../../utils/filterData";


// constants
export const FETCH_REPOSITORIES = "FETCH_REPOSITORIES";
export const INVITE_COLLABORATORS = "INVITE_COLLABORATORS";
export const FILTER_REPOSITORIES = "FILTER_REPOSITORIES"

const fetchAll = (res) => ({
  type: FETCH_REPOSITORIES,
  payload: res,
});

export const filterAll = (res) => ({
  type: FILTER_REPOSITORIES,
  payload: res
})

export const fetchRepositories = () => async (dispatch) =>
  fetchAPI({ method: "get", url: `/repos` }).then(({ data }) =>
    dispatch(fetchAll(filterRepositories(data.response.repositories)))
  );

export const inviteCollaborators = ({ courseId, name }) => async (dispatch) =>
  fetchAPI({
    method: "put",
    url: `/repos/Plataforma5/${name}/collaborators`,
    body: { courseId },
  });


