const workshops: string[] = [
  "Estructura de Datos I",
  "Estructura de Datos II",
  "Selectores",
  "Sorting",
  "Game of Life",
  "Node",
  "Node CLI",
  "Servidores",
  "Tweety",
  "SQL",
  "Schema Design",
  "Tweety.sql",
  "Promisifying",
  "Wiki",
  "Testing Wiki",
  "Blackbird",
  "Passport",
  "Pimp My Site",
  "AJAX y DOM",
  "React 0",
  "React I",
  "React II",
  "React III",
  "Context",
  "Redux",
  "Docker"
]

export default workshops