import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, VFC } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import { RootState } from '../utils/types.reducer';
import { instance } from '../config/axios.instance';
import { checkUSer } from '../store/auth/auth.actions';

import AuthRoutes from './Auth';
import PrivateRoutes from './Private';
import Loading from '../components/loader';
import RouteComponent from './utils/RouteComponent';

const Routes: VFC = () => {
  const dispatch: any = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(checkUSer());

    if (user.token) {
      instance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${user.token}`;
    }

    setTimeout(() => setLoading(false), 500);
  }, [dispatch, user.token]);

  if (loading) return <Loading styles={{ minHeight: '100vh' }} />;

  return (
    <BrowserRouter>
      <Switch>
        <RouteComponent
          pathnameRedirect="/"
          isAuth={!user.token}
          component={AuthRoutes}
          path="/auth"
        />

        <RouteComponent
          pathnameRedirect="/auth/login"
          isAuth={!!user.token}
          component={PrivateRoutes}
          path="/"
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
