import React, { useEffect, useRef, useState, VFC } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import XLSX from "xlsx";
import { useDispatch } from "react-redux";
//----------------------------------------------------------------//
import localStyles from "./index.module.css";
import { createStudents } from "../../store/students/students.actions";
import Button from "../../components/button";

interface MatchParams {
  id: string;
}

interface Student {
  name: string;
  email: string;
  github: string;
  courseId: string;
}

const AddStudentsBySheets: VFC = () => {
  const history = useHistory();
  const dispatch: any = useDispatch();
  const fileInput = useRef<HTMLInputElement>(null);
  const match = useRouteMatch<MatchParams>();
  const [files, setFiles] = useState<FileList | null>(null);
  const [students, setStudents] = useState<string[][]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = match.params;

  useEffect(() => {
    fileInput.current &&
      fileInput.current.addEventListener("change", function (e: Event) {
        setFiles((e.target as HTMLInputElement).files);
      });
  }, [fileInput]);

  useEffect(() => {
    files &&
      (async () => {
        try {
          const data = await files[0].arrayBuffer();
          const workbook = XLSX.read(data);
          const sheetJson: {}[] = XLSX.utils.sheet_to_json(
            workbook.Sheets.Sheet1
          );
          const students = sheetJson.map((row) =>
            (Object.values(row) as [string]).map(
              (s) => s.replaceAll("\r", "").replaceAll("\n", "").trim() // -> Algunos strings pueden tener '\n\r' y/o espacios, por eso despues del object.values hacemos los replace y el trim(). NOTE se podría refactorizar con regex en un solo replaceAll()
            )
          );
          setStudents(students);
        } catch (error) {
          console.log(error);
        }
      })();
  }, [files]);

  const seedSheet = async () => {
    try {
      setLoading(true);
      const studentsBody: Student[] = students.map((student) => ({
        name: student[0],
        email: student[1],
        github: student[2],
        imgUrl: student[3],
        courseId: id,
      }));
      await dispatch(createStudents(studentsBody));
      setLoading(false);
      history.push(`/courses/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={localStyles.upload}>
      <h1>Suba el archivo .xlsx</h1>
      <input type="file" ref={fileInput} />
      {students.length ? (
        <>
          <div className={localStyles.container}>
            <p>Nombre completo</p>
            <p>E-mail</p>
            <p>Github</p>
            <p>Imagen URL</p>
          </div>
          {students.map(([name, email, github, imgUrl], i) => (
            <div className={localStyles.container}>
              <p key={i}>{name}</p>
              <p key={i}>{email}</p>
              <p key={i}>{github}</p>
              <p key={i}>{imgUrl}</p>
            </div>
          ))}
          <Button
            text="Enviar"
            type="primary"
            onClick={seedSheet}
            disabled={loading}
          />
        </>
      ) : null}
    </div>
  );
};

export default AddStudentsBySheets;
