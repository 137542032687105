import { useEffect } from 'react';

export const useOutsideClick = (
  ref: any,
  displayMenu: any,
  setDisplayMenu: any,
) => {
  useEffect(() => {
    function handleClickOutside(e: any) {
      e.stopPropagation();

      if (ref.current && !ref.current.contains(e.target))
        setDisplayMenu(!displayMenu);
    }

    document.addEventListener('mousedown', handleClickOutside);
  }, [ref, displayMenu, setDisplayMenu]);
};
