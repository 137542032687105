import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Fragment, FC, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
//-------------------------------------------------------------------//
import Input from "../../components/input";
import Title from "../../components/title";
import Button from "../../components/button";
import Wrapper from "../../components/wrapper";
import validations from "../../utils/validations";
import { createStudent } from "../../store/students/students.actions";

interface IProps extends RouteComponentProps<MatchParams> {}
interface MatchParams {
  id: string;
}

type FormData = {
  name: string;
  email: string;
  github: string;
  imgUrl?: string;
};

const AddStudent: FC<IProps> = ({ match }) => {
  const { id } = match.params;
  const history = useHistory();
  const dispatch: any = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, errors, register } = useForm<FormData>();

  const [err, setErr] = useState({
    status: false,
    message: "",
  });

  const onCreate = ({ email, github, name, imgUrl }: FormData) => {
    setLoading(true);

    dispatch(createStudent({ email, github, name, courseId: id, imgUrl }))
      .then(() => {
        setLoading(false);
        history.push(`/courses/${id}`);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setErr({
          ...err,
          status: true,
          message: "Ha ocurrido un error inesperado, intentá nuevamente!",
        });
      });
  };

  return (
    <Fragment>
      <Title text="Agregar Alumno" />

      <form onSubmit={handleSubmit(onCreate)} style={{ width: "100%" }}>
        <Wrapper styles={{ marginTop: "24px" }}>
          <Input
            name="name"
            error={errors.name}
            styles={{ width: "30%" }}
            placeholder="Nombre y Apellido"
            referencia={register(validations.required())}
          />

          <Input
            name="email"
            error={errors.email}
            styles={{ width: "30%" }}
            placeholder="Correo Electrónico"
            referencia={register(validations.email)}
          />

          <Input
            name="github"
            placeholder="GitHub"
            error={errors.github}
            styles={{ width: "30%" }}
            referencia={register(validations.required())}
          />

          <Input
            name="imgUrl"
            placeholder="Imagen URL"
            error={errors.imgUrl}
            referencia={register()}
            styles={{ width: "30%", textAlign: "center" }}
          />

          {err.status && <div>{err.message}</div>}

          <Button submit text="Crear" type="secondary" disabled={loading} />
        </Wrapper>
      </form>
    </Fragment>
  );
};

export default AddStudent;
